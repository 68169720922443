<template>
  <div class="layout-footer-container flex">
    <div class="lf-left">
    </div>
    <div class="lf-content flex-1">
        <div class="youqing"><i class="el-icon-link"></i><span>友情链接</span></div>
        <div  class="xuanze">
          <el-row>
            <el-col :span="5">

              <el-select v-model="value1"   @change="change($event,zfjgLinkList)"  placeholder="请选择">
                <el-option
                    v-for="item in zfjgLinkList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="5" :offset="1">
              <el-select v-model="value"  @change="change($event,jrjgLinkList)" placeholder="请选择">
                <el-option
                    v-for="item in jrjgLinkList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="5" :offset="1">
              <el-select v-model="value2" @change="change($event,fwjgLinkList)" placeholder="请选择">
                <el-option
                    v-for="item in fwjgLinkList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>
      <el-divider></el-divider>
      <div class="flex" style="margin-top: 45px;width:900px;">
        <div class="flex-1">
          <div class="mg5"><span class="colfc">主管单位：</span>{{datas.zgdw}}</div>
          <div class="mg5"><span class="colfc">技术支持：</span>{{datas.yyjg}}</div>
          <div class="mg5"><span class="colfc">平台网址：</span>{{datas.pturl}}</div>
        </div>
        <div  style="text-align:center;" class="flex">
          <div>
            <div class="ewms"><el-image :src="datas.xcxewm"></el-image></div>
            <div>微信小程序</div>
          </div>
          <div>
            <div class="ewms"><el-image :src="datas.gzhewm"></el-image></div>
            <div>微信公众号</div>
          </div>
          <div>
            <div  class="ewms"><el-image :src="datas.h5ewm"></el-image></div>
            <div>手机网页版</div>
          </div>
        </div>
      </div>
      <div class="dibu">
        <!-- <span class="colfc">技术支持单位：</span>
        <span>{{datas.jszcdw}}</span> -->
        <i class="gongan"></i><span class="colfc">ICP备案证：</span>
        <span>{{datas.icpbah}}</span>
      </div>
    </div>
    <!-- <div class="lf-right">
      <div class="wright">
          <div style="margin-top: 15px">用微信扫描下方</div>
          <div>二维码进入小程序</div>
          <div class="xiaochengxu"><el-image :src="datas.xcxewm"></el-image></div>
          <div>用微信扫描下方</div>
          <div>二维码进入公众号</div>
          <div class="gzh"><el-image :src="datas.gzhewm"></el-image></div>
      </div>
    </div> -->
  </div>
</template>

<script>
import {indexpublic} from "../request/moudle";

export default {
  name: "bottom",
  data(){
    return {
      datas:'',
      fwjgLinkList:[],
      jrjgLinkList:[],
      zfjgLinkList:[],
      value:'',
      value1:'',
      value2:''
    }
  },
  created() {
    this.loadconfig()
  },
  methods:{
    change(e,arr){
      for (const  val of arr){
        if(val.id==e&&val.link){
          window.open(val.link,'_blank')
        }
      }
    },
    loadconfig(){
      this.$api.index.indexpublic().then((res)=>{
        this.datas=res.data
        this.fwjgLinkList=res.data.fwjgLinkList
        this.jrjgLinkList=res.data.jrjgLinkList
        this.zfjgLinkList=res.data.zfjgLinkList
        this.value= this.jrjgLinkList[0].id
        this.value1= this.zfjgLinkList[0].id
        this.value2= this.fwjgLinkList[0].id
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.gongzhonghao{
  width: 129px;
  height: 129px;
}
.ewms{
  width: 89px;
  height: 89px;
  margin: 0 15px;
  margin-bottom:5px;
}
/deep/ .el-select-dropdown__wrap el-scrollbar__wrap{
  margin-bottom: -14px!important;
}
    .layout-footer-container{
      height: 350px;
      background-color: #3E445B;
      width: 100%;
      position: relative;
      color: #fff;
      .mg5{
        margin: 10px 0;
      }
      /deep/ .el-input__inner{
        background-color: #3E445B!important;
        border: 1px solid #585D66;
        color: #fff;
      }
      /deep/ .el-select-dropdown{
        background-color: #585D66!important;
        border: none!important;
      }
      /deep/ .el-divider{
         background-color: #585D66!important;
      }
      .lf-left{
        width: 350px;
        position: relative;
        .wleft{
          position: absolute;
          right: 65px;
          top: 50%;
          margin-top: -75px;
        }

        .weixin{
          width: 60px;
          height: 60px;
          background: url("../assets/icons/weixin.png");
          background-size: 100% 100%;
          margin: 15px 0 10px 0;
        }
      }
      .lf-content{
        .youqing{
          margin: 30px 0;
        }
        .dibu{
          margin-top: 24px;
          margin-left: 25%;
        }
        .gongan{
          display: inline-block;
          width: 17px;
          height: 17px;
          background: url("../assets/icons/gongan.png") no-repeat;
          background-size: 100% 100%;
          vertical-align: middle;
          margin-top: -4px;
          margin-right: 10px;
          margin-left: 120px;
        }
      }
      .colfc{
        opacity: 0.8;
      }
    }

</style>